import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

import SmartFeedFiltersComponent from "../components/SmartFeedWithFilters_WT"

require("es6-promise").polyfill()

const getURL = function getURL() {
  return typeof window !== "undefined" ? window.location.href : ""
}

export default function VacanciesListPage({ props }) {
  const [vacancyLoading, setVacancyLoading] = useState(true)

  return (
    <Layout {...props}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Jobs at the Woodland Trust</title>
        <meta
          name="title"
          content="Jobs at Woodland Trust"
        />
        <meta
          name="description"
          content="The UK's largest woodland conservation charity. We want to see a UK rich in native woods and trees. For people. For wildlife. We can't achieve our vision without you."
        />
      </Helmet>

      <SmartFeedFiltersComponent
        setVacancyLoading={setVacancyLoading}
        vacancyLoading={vacancyLoading}
        apiKey={"2KE5dbfuV0FDuOYHEbrfX254tBDfXQJbUwx9EXKCNOE"}
        group={false}
        gdprKey=""
        CompanyGroupName="Woodland Trust"
        VacancyPageBaseURL="https://jobs.woodlandtrust.org.uk/vacancies/vacancy/"
        ReturnToSearchURL="https://jobs.woodlandtrust.org.uk/vacancies/"
        contentDataData={{}}
      />
    </Layout>
  )
}
